export const statuses = {
  offline: {
    name: "Offline",
    avatarBadge: undefined,
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "OFF",
    toolbarStatus: "busy",
    saveLastStatus: false,
  },
  ready: {
    name: "Ready",
    avatarBadge: undefined,
    avatarStatus: "available",
    idleTimer: true,
    //phoneState: "online",
    phoneState: null,
    iconSymbol: "RDY",
    toolbarStatus: "ready",
    saveLastStatus: true,
  },
  idle: {
    name: "Idle",
    avatarBadge: undefined,
    avatarStatus: "away",
    idleTimer: false,
    phoneState: null,
    iconSymbol: "IDL",
    toolbarStatus: "ready",
    saveLastStatus: false,
  },
  "in-ticket": {
    name: "In Ticket",
    avatarBadge: "TK",
    avatarStatus: null,
    idleTimer: true,
    phoneState: null,
    iconSymbol: "TK",
    toolbarStatus: "ready",
    saveLastStatus: false,
  },
  "in-chat": {
    name: "In Chat",
    avatarBadge: "CH",
    avatarStatus: null,
    idleTimer: false,
    phoneState: null,
    iconSymbol: "CH",
    toolbarStatus: "ready",
    saveLastStatus: false,
  },
  "on-call": {
    name: "On Call",
    avatarBadge: "\u260e",
    avatarStatus: null,
    idleTimer: false,
    phoneState: null,
    iconSymbol: "OC",
    toolbarStatus: "ready",
    saveLastStatus: false,
  },
  "wrap-up": {
    name: "Wrap Up",
    avatarBadge: "WU",
    avatarStatus: null,
    idleTimer: false,
    phoneState: null,
    iconSymbol: "WU",
    toolbarStatus: "ready",
    saveLastStatus: false,
  },
  break: {
    name: "Break",
    avatarBadge: "BK",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "BK",
    toolbarStatus: "break",
    saveLastStatus: true,
  },
  meeting: {
    name: "Meeting",
    avatarBadge: "MT",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "MT",
    toolbarStatus: "meeting",
    saveLastStatus: true,
  },
  lunch: {
    name: "Lunch",
    avatarBadge: "LN",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "LN",
    toolbarStatus: "lunch",
    saveLastStatus: true,
  },
  "be-right-back": {
    name: "Be Right Back",
    avatarBadge: "BB",
    avatarStatus: null,
    idleTimer: false,
    //phoneState: 'away',
    phoneState: null,
    iconSymbol: "BB",
    toolbarStatus: "be-right-back",
    saveLastStatus: true,
  },
  social: {
    name: "Social",
    avatarBadge: "SO",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "SO",
    toolbarStatus: "social",
    saveLastStatus: true,
  },
  busy: {
    name: "Busy",
    avatarBadge: "BS",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "BS",
    toolbarStatus: "busy",
    saveLastStatus: true,
  },
  training: {
    name: "Training",
    avatarBadge: "TR",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "TR",
    toolbarStatus: "training",
    saveLastStatus: true,
  },
  "happiness-advocate": {
    name: "Happiness Advocate",
    avatarBadge: "HA",
    avatarStatus: null,
    idleTimer: false,
    phoneState: "offline",
    iconSymbol: "HA",
    toolbarStatus: "happiness-advocate",
    saveLastStatus: true,
  },
};

export const getStatus = (status) => {
  if (statuses[status]) {
    return statuses[status];
  }
  return {};
};

export const checkTicketStatus = (status, ticket) => {
  if (["ready", "idle", "in-chat", "in-ticket"].indexOf(status) === -1) {
    return status;
  }
  if (
    ticket.channel?.sessionActive &&
    (ticket.channel?.name === "native_messaging" ||
      ticket.channel?.name === "chat")
  ) {
    return "in-chat";
  }
  if (ticket.id || ticket.isNew) {
    return "in-ticket";
  }
  return status;
};
